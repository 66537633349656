<template>
  <Loader v-if="loading" />
  <div v-else>
    <navigation-drawer
      :language="language"
      :categories="menuCategories"
      :items="items"
      @closed="drawer = false"
    />

    <Navigation
      :showFullPageSpacer="false"
      :showHeaderWrapper="false"
      :showMenuBar="false"
    >
      <MenuHeader :breadcrumbs="breadcrumbs" />

      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
      >
        <router-view> </router-view>
      </transition>
    </Navigation>
  </div>
</template>

<script>
import Navigation from "@/components/navigation/Navigation.vue";
import Loader from "@/components/loader/Loader.vue";
import MenuHeader from "@/components/menu/MenuHeader.vue";
import NavigationDrawer from "../components/menu/NavigationDrawer.vue";

import { mapGetters } from "vuex";

export default {
  components: { Navigation, Loader, MenuHeader, NavigationDrawer },
  mounted() {
    this.$store.commit("carousel/setImage", "/assets/carousel/carousel_5.jpg");
  },
  computed: {
    ...mapGetters("loader/", ["loading"]),
    ...mapGetters("system/", ["breadcrumbs", "language", "dayMenuCategory"]),
    ...mapGetters("business", ["categories", "items"]),
    menuCategories() {
      return this.categories.filter((c) => c.uuid !== this.dayMenuCategory);
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>
