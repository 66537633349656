<template>
  <div class="primary darken-2 border">
    <v-row justify="space-between" align="center" class="ma-auto white-row">
      <v-col cols="10" class="text-start">
        <v-breadcrumbs :items="breadcrumbs" class="breadcrumbs">
          <template v-slot:divider>
            <v-icon
              color="white"
              :x-small="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              >fas fa-chevron-right</v-icon
            >
          </template>
          <template v-slot:item="{ item }">
            <router-link v-if="item.to" :to="item.to">
              <v-icon v-if="item.icon" class="icon-size" color="white">{{
                item.icon
              }}</v-icon>
              <div v-else class="v-breadcrumbs__item">{{ item.text }}</div>
            </router-link>
            <div v-else class="v-breadcrumbs__item">{{ item.text }}</div>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="2" class="text-end">
        <v-icon class="icon-size" color="white" @click="toogleDrawer()"
          >fas fa-bars</v-icon
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: Array,
    language: Object,
    categories: Array
  },
  methods: {
    toogleDrawer() {
      this.$store.commit("system/toogleDrawer");
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 1rem;
}
.white-row {
  z-index: 1;
  position: relative;
}
.border {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
::v-deep .v-breadcrumbs {
  padding: 10px 10px;
}
::v-deep .v-breadcrumbs .v-breadcrumbs__item {
  color: white;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: "Raleway";
  font-weight: 600;
}
::v-deep .v-breadcrumbs a {
  color: white;
  text-decoration: underline;
  &:hover {
    color: white !important;
    text-shadow: 0 0 0 var(--v-primary-base);
  }
}
</style>
