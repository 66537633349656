<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    app
    width="500"
    style="overflow:scroll"
    @input="$emit('close')"
  >
    <v-container class="white elevation-1">
      <v-row v-if="$vuetify.breakpoint.mdAndDown">
        <v-col>
          <BasicLanguageSwitch :size="1.7" />
        </v-col>
      </v-row>
      <v-row><v-divider></v-divider></v-row>
      <v-row justify="center">
        <v-col cols="auto" v-if="false">
          <v-btn text fab small color="red">
            <v-icon small>fas fa-heart</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="false">
          <v-btn text fab small class="gray">
            <v-icon small>fas fa-user</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="false">
          <v-btn text fab small class="gray">
            <v-icon small>fas fa-shopping-cart</v-icon>
          </v-btn>
        </v-col>
        <v-col justify="center">
          <v-btn text fab small class="gray" @click="$router.push('/')">
            <v-icon>fas fa-home</v-icon></v-btn
          ></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            text
            fab
            small
            class="gray"
            @click="$store.commit('system/setDrawer', false)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-list>
      <v-list-group v-for="(cat, index) in categories" :key="index">
        <template v-slot:activator>
          <v-list-item
            link
            :to="`/menu/${cat.name[language.lcid]}`"
            class="pages-link"
            active-class="active-page"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ cat.name[language.lcid] }}
              </v-list-item-title>
            </v-list-item-content>
            <v-divider></v-divider>
          </v-list-item>
        </template>

        <v-list-item
          v-for="(item, index) in items.filter((i) => i.category === cat.uuid)"
          :key="`item-${index}`"
          :to="`/menu/${cat.name[language.lcid]}/${item.title[language.lcid]}`"
          link
          class="sub-pages-link"
          active-class="active-page"
        >
          <v-list-item-title>
            {{ item.title[language.lcid] }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import BasicLanguageSwitch from "@/components/system/BasicLanguageSwitch.vue";

export default {
  components: { BasicLanguageSwitch },
  computed: {
    drawer: {
      get() {
        return this.$store.state.system.drawer;
      },
      set(value) {
        this.$store.commit("system/setDrawer", value);
      },
    },
  },
  props: {
    language: Object,
    categories: Array,
    items: Array,
  },
  data() {
    return {
      model: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.gray {
  color: gray;
}
nav {
  z-index: 1000 !important;
  background-image: radial-gradient(white, #ffffff00),
    url(/assets/pattern/full-bloom.png);
  background-repeat: repeat;
}
.sub-pages-link {
  color: gray !important;
  padding-left: 4rem;
}
.pages-link {
  transition: background-color 0.4s ease-in;
  text-align: start;
  text-transform: capitalize;
  transition: color 0.4s ease-in;
  font-size: 1rem;
  color: var(--v-primary-darken1) !important;
  text-decoration: none;
  display: block;
  font-family: "Raleway";
  font-weight: 900;
  position: relative;
}
.active-page {
  pointer-events: none;
  color: var(--v-primary-darken3) !important;
}
</style>
